@import "variables";
@import "mixins";
@import "placeholders";
@import "service-forms";
@import "support-page";

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('../fonts/Montserrat-Regular.eot');
  src: url('../fonts/Montserrat-Regular.woff2') format('woff2'),
  url('../fonts/Montserrat-Regular.woff') format('woff'),
  url('../fonts/Montserrat-Regular.ttf') format('truetype'),
  url('../fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg'),
  url('../fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

#header-bottom {
  background-color: #f4f4f4;
  padding: 0 5px;
  color: #13457c;
  height: 100%;
  white-space: nowrap;

  .container {
    text-align: right;
    height: 100%;
    padding: 0;
    white-space: nowrap;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .h3 {
    float: right;
    margin: 0 5px 0 0;
    font-size: 13px;
    font-family: $font-regular;
    position: relative;
    top: 50%;
    -webkit-transform:translateY(-50%);
    -moz-transform:translateY(-50%);
    -ms-transform:translateY(-50%);
    -o-transform:translateY(-50%);
    line-height: 35px;
  }
  #header-international {
    display: none !important;
  }
}

@media (min-width: 992px) {
  #header-search .form-text {
    display: inline-block !important;
    width: 220px !important;
  }
}

#header-help,
#header-international,
#header-search {
  white-space: nowrap;
  text-align: center;
  padding: 0;

  .fa-question-circle,
  .glyphicon-search,
  .fa-globe,
  .fa-mobile {
    color: #6e716e;
  }

  @include breakpoint($screen-sm) {
    padding: 0 7px 0 8px;
  }

  @include breakpoint($screen-lg) {
    padding: 0 15px;
  }
}

#header-breadcrumbs {
  flex: 0 1 100%;
  text-align: left;
  display: none;
  order: 0;

  @include breakpoint($screen-sm) {
    //display: block;
  }

  @include breakpoint($screen-md) {
    padding-left: 15px;
  }

  @include breakpoint($screen-lg) {
    display: block;
    flex: 4 1 auto;
    overflow: hidden;

  }

  .breadcrumb {
    margin-bottom: 0;
    padding: 0;
    background-color: transparent;

    & > li {
      margin: 0;

      & + li:before {
        content: url(../img/crumb_arr.svg);
        display: inline-block;
        vertical-align: middle;
        line-height: 35px;
      }

      & > a {
        color: #706f73;
        font-family: "Helvetica Neue", Arial, sans-serif;
        font-size: 13px;
        line-height: 35px;

        @include breakpoint($screen-lg) {
          display: inline-block;
          max-width: 226px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          vertical-align: middle;
        }
      }

      & > span {
        display: inline-block;
        max-width: 226px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        color: #706f73;
        font-family: "Helvetica Neue", Arial, sans-serif;
        font-size: 13px;
        line-height: 35px;
      }

    }
  }
}

#header-search {
  color: #13457c;
  font-size: 22px;
  order: 1;

  &.searching {
    border-left: none;
    border-right: none;
  }

  .form-group {
    margin: 0;
    text-align:center;
    width:auto;

    .input-group {
      margin: 0 auto;
      text-align:center;
      width:auto;
    }
  }

  .input-group-btn {
    width: auto;
    line-height: 35px;
  }

  .form-text {
    width: 220px;
    margin-right: 5px;
    margin-top: 2px;
    border-radius: 0;
    font-size: 13px;
    line-height: 23px;
    padding: 3px 12px;
    height: auto;
    min-height: 31px;
    box-shadow: none;
    font-family: Helvetica, Arial,sans-serif;
    &:focus {
      border-color: #5d9632;
    }
  }

  button {
    background: #5d9632 url("../img/search-icon.svg") center no-repeat;
    width: 31px;
    height: 31px;
    border-radius: 0;
    margin-left: -5px;

    &:hover {
      color: #555;
    }

    &.form-submit {
      display: none;
    }
  }
}

#header-help {
  cursor: pointer;
  color: #A7A7A7;
  font-size: 12px;
  padding: 0;
  flex: 0 1 10%;
  order: 3;

  @include breakpoint($screen-sm) {
    flex: 0 1 10%;
    order: 1;
    padding: 0 7px 0 0;
  }

  @include breakpoint($screen-lg) {
    flex: 0 1 auto;
    order: 0;
    padding: 0 15px;
  }

  &:hover i {
    color: #555;
  }

  .help-container {
    text-align: right;
    overflow: hidden;
    position: relative;
    right: 0;
    display: inline-block;
    vertical-align: top;
    width: 110px;
    height: 35px;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;

    &.open {
      -webkit-transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      transition: all 0.4s ease;
      min-width: 100px;
    }
  }

  .help-text {
    white-space: nowrap;
    height: 100%;
    position: absolute;
    text-align: right;
    top: 0;
    right: 0;
    -webkit-transition: all 0.8s ease-in-out;
    -moz-transition: all 0.8s ease-in-out;
    -o-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out;
    width: 100%;
    float: right;
  }

  .help-phone {
    white-space: nowrap;
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    -webkit-transition: all 0.8s ease-in-out;
    -moz-transition: all 0.8s ease-in-out;
    -o-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out;
  }

  .help-text,
  .help-phone {
    &.out {
      right: -150px;
    }
  }

  .h3 {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform:translateY(-50%);
    -moz-transform:translateY(-50%);
    -ms-transform:translateY(-50%);
    -o-transform:translateY(-50%);
  }

  .fa-question-circle,
  .fa-mobile {
    font-size: 22px;
    line-height: 35px;
  }

  .fa-question-circle {
    margin: 0;
  }

  .fa-mobile {
    margin-right: 5px;
  }

  .shake {
    -webkit-animation-name: shake;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: shake;
    -moz-animation-duration: 1s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    animation-name: shake;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

#header-international {
  color: #13457c;
  font-size: 22px;
  position: relative;
  text-align:center;

  .h3 {
    display: none;
    margin: 0 5px 0 0;
    cursor: pointer;
    float: none;

    @include breakpoint($screen-sm) {
      display: inline-block;
    }
  }

  .fa-globe {
    line-height: 35px;
    margin: 0;
  }

  .h3,
  .fa-globe {
    vertical-align: middle;
  }

  span {
    color: #A7A7A7;
    font-size: 12px;
    vertical-align: middle;
  }

  i {
    cursor: pointer;

    &:hover {
      color: #555;
    }
  }
}

#header-search,
#header-international {
  flex: 0 1 10%;
  order: 2;

  @include breakpoint($screen-sm) {
    order: 1;
    flex: 0;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      margin-right: 20px;
    }
  }

  @include breakpoint($screen-lg) {
    flex: 0 1 auto;
    order: 0;
  }
}

.block-facetapi {
  padding: 15px;

  @include breakpoint($screen-sm) {
    display: block;
    padding: 15px 20px 0;
  }
}

.block-kerrdental-search {
  padding: 15px;

  @include breakpoint($screen-sm) {
    padding: 15px 20px;
  }

  &#block-kerrdental-search-items-per-page-block {
    @include breakpoint($screen-sm) {
      padding: 15px 20px 0;
    }
  }
}

.block-facetapi,
.block-kerrdental-search {
  display: none;
  clear: none;
  padding: 15px 20px 0;
  background-color: #f0f0f0;

  @include breakpoint($screen-sm) {
    display: block;
  }

  h2,
  .control-label {
    @extend %filter-label
  }

  .select2-container {
    width: 100% !important;
    text-align: left;
  }

  .form-group {
    margin-bottom: 0;
  }

  ul {
    padding-left: 5px;

    ul {
      padding-left: 20px;
    }
  }

  li {
    margin: 5px 0 3px 0;

    li {
      margin: 0 0 3px 0;
    }
  }
}

.block-kerrdental-search {
  @include breakpoint($screen-sm) {
    padding: 15px 20px;

  }
}

.field-name-field-resource-file-name {
  a {
    font-size: 14px !important;
  }
}

.flex-wrapper {
  @include breakpoint($screen-sm) {
    display: flex;

    .flex-block {
      &.-left {
        flex: 1 1 auto;
      }

      &.-right {
        flex: 3 3 auto;
      }
    }
  }
}

.kerr-page-download-center {
  .flex-wrapper {
    @include breakpoint($screen-sm) {
      padding: 0 30px;
    }
  }

  .common-header {
    padding: 0;

    @include breakpoint($screen-sm) {
      padding: 0 20px;
    }
  }
}

#resource-center-quick-search {
  margin: 0;

  .row {
    margin-left: -15px;
    margin-right: -15px;
  }

  .search-container {
    margin: 0;
    padding: 10px 15px;
  }

  #sds-finder,
  #ifu-finder {
    width: 100%;
  }

  .select2-container {
    text-align: left;
    vertical-align: text-top;
  }

  @include breakpoint($screen-sm) {
    margin: 0 25px;

    .row {
      margin: 0;
    }

    .search-container {
      margin: 15px 5px;
    }

    #sds-finder,
    #ifu-finder {
      width: 80%;
    }
  }
}

#resource-center-general-search {
  background-color: #f0f0f0;
  padding: 18px 15px;
  margin: 20px 0;

  form {
    & > div {
      position: relative;
    }
  }

  .form-text {
    display: block;
    border-radius: 0;
    width: 100%;
    margin: 0 auto;
    padding: 6px 39px 6px 12px;
  }

  button {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    border: none;
    height: 34px;
    padding: 2px 8px;
    width: 34px;
    height: 34px;
    background: url(../img/search-icon.svg) center no-repeat;
  }

  @include breakpoint($screen-sm) {
    .form-text {
      padding: 6px 52px 6px 12px;
    }

    button {
      padding: 2px 10px;

      &:after {
        font-size: 1.4em;
      }
    }
  }

  @include breakpoint($screen-md) {
    form {
      & > div {
        position: relative;
        width: 51%;
      }
    }
  }
}

.view-id-resource_center {
  .view-header {
    color: #706f73;
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-size: 13px;
    line-height: 18px;
  }

  .node-teaser {
    border-bottom: 1px solid #ddd;
    padding: 15px 0;
  }

  @include breakpoint($screen-sm) {
    .view-header {
      margin: 10px 0;
    }
  }
}

.view-id-sds_emea {
  .node-teaser  {
    margin: 0 20px;
    border-bottom: 1px solid #ddd;
    padding: 15px 0;
  }
}

.field-name-field-resource-image {
  img {
    border: 1px solid #f2f2f2;
    padding: 2px;
    transition: all 0.5s;

    &:hover {
      transform: scale(1.5);
    }
  }
}

.view-resource-center {
  .field-name-field-resource-revision,
  .field-name-field-resource-release-date,
  .field-name-field-resource-file-size {
    margin-bottom: 3px;
  }
}

.field-more {
  &.field-label-inline {
    .field-more-teaser,
    .field-label {
      margin-right: 5px;
    }
  }

  .field-more-content-multiple {
    position: relative;
    padding: 0 0 20px;
  }

  .field-more-other,
  .field-more-content {
    max-height: 999px;
    //transition: max-height .5s linear;
  }

  .view-more-wr {
    .view-more {
      color: #428BCA;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .view-more {
    &.more {
      display: none;
    }
  }

  &.closed {
    .view-more {
      &.more {
        display: inline-block;
      }

      &.less {
        display: none;
      }
    }

    .field-more-content {
      max-height: 40px;
      overflow: hidden;
    }
  }

  &.multiple {
    .field-more-teaser,
    .view-more-wr {
      display: inline-block;
    }

    .view-more-wr {
      display: block;
      position: absolute;
      bottom: 0;
    }

    &.closed {
      .field-more-content-multiple {
        position: relative;
        padding: 0;
      }

      .view-more-wr {
        display: inline-block;
        position: static;
      }

      .field-more-other {
        max-height: 0;
        overflow: hidden;
      }
    }
  }
}

.info-section-content {
  .filters {
    padding-bottom: 20px;

    > .row {
      vertical-align: bottom;

      > div {
        display: inline-block;
        float: none;
      }
    }
  }

  .row {
    margin-right: -15px;
    margin-left: -15px;
  }

  .block-title {
    font-family: $font-light;
    font-size: 1em;
    font-weight: normal;

    @media (max-width: 480px) {
      font-size: 0.8em;
    }
  }

  .select2-container {
    width: 100% !important;
    max-width: 200px;
  }

  .form-item-items-per-page {
    & > .control-label {
      display: none;
    }
  }
}

.kerr-page-download-center {
  .form-item-items-per-page {
    & > .control-label {
      display: none;
    }
  }
}


.node-resource-asset {
  .field-name-body {
    margin-top: 8px;
  }

  //.field-name-field-resource-language {
  //  margin-top: 10px;
  //}
}

.node.node-product {
  .view-id-resource_center .view-header {
    margin: 0;
    color: #706f73;
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-size: 13px;
    line-height: 18px;
  }

  .info-section-content .filters {
    padding-bottom: 10px;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow {
    background-color: #fff;
    width: 25px;
    background-color: #5d9632;
  }

  .select2-container--default .select2-selection--single {
    border-color: #aaa;
  }

}

#home-mid {
  .right {
    > div:not(.home-mid-support) a {
      margin-bottom: 15px;
    }

    h4 {
      font-family: $font-light;
      font-size: 30px;
      display: inline-block;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      justify-content: center;
      @media (min-width: 992px) {
        justify-content: left;
      }
      align-items: center;
      vertical-align: top;
      @media (max-width: 500px) {
        flex-direction: column;
      }

      span {
        display: inline-block;
        height: 48px;
        width: 40px;
        background: transparent url(../img/warranty_icon.svg) no-repeat 0 0 / contain;
        margin-right: 10px;

        & + div {
          display: inline-block;
        }
      }
    }

    .home-mid-demo {
      h4 {
        span {
          background: transparent url(../img/contact.svg) no-repeat center / cover;
        }
      }
    }

    .home-mid-support {
      h4 {
        span {
          background: transparent url(../img/support_info.svg) no-repeat center / cover;
        }
      }
    }
  }

  .home-mid-procedure {
    .inner {
      position: relative;
      padding-left: 5px;

      .bottom {
        padding-top: 10px;
        margin-left: -15px;
        margin-right: -15px;
        font-family: "Helvetica Neue LT Std Lt", Arial, sans-serif;
      }
    }
  }

  .home-mid-procedure {
    .inner {
      padding-left: 20px;

      .row.title {
        text-decoration: none;

        .field-name-field-homepage-procedure-title {
          .field-item {
            font-size: 30px;
            font-family: "Helvetica Neue LT Std Lt", Arial, sans-serif;
            line-height: 1.2;
            font-weight: normal;
            color: #fff;
            position: relative;
          }
        }
      }
    }
  }

  .home-mid-promotion {
    .promo-desc {
      padding-left: 20px;
      padding-right: 0;
      color: #706f73;

      .title {
        text-decoration: none;
        color: #706f73;
        font-family: "Helvetica Neue LT Std Lt", Arial, sans-serif;
        font-size: 30px;
        line-height: 33px;
      }

      .line {
        border-bottom: 1px solid #1c2b39;
      }

      .row {
        margin-right: -20px;
      }

      .promo-title {
        margin-top: 10px;
        font-size: 20px;
        font-family: $font-regular;
        line-height: 1.2;
      }

      p {
        margin-top: 25px;
        font-size: 16px;
        letter-spacing: 0.3px;
        line-height: 1.45;
      }
    }
  }
}

  #header-bottom{
    .help-container{
      display: none;
      &.mobile-open{
        display: inline-block !important;
        transition: all 0.4s ease;
        min-width: 100px;
      }
      @include breakpoint($screen-lg){
        display: inline-block;
      }
      .help-text .h3{
        font-size: 0;
        @include breakpoint($screen-lg){
          font-size: 12px;
        }
      }
    }
    .input-group{
      @include breakpoint($screen-lg){
        display: inline-block;
      }
    }
    #header-international{
      div.h3{
        display: none;
        @include breakpoint($screen-lg){
          display: inline-block;
        }
      }
    }

  }

.glyphicon.glyphicon-search{
  top: 3px;
  @include breakpoint($screen-lg){
    top: 1px;
  }
}

  @media (min-width: 992px) and (max-width: 1199px) {
    #header-bottom {
        #header-breadcrumbs { /*Override IE issues and cross-platform*/
          display: block !important;
          order: 3;
          flex: 1 1 40%
        }
    }

    #header-help {
        flex: 0 1 10%;
    }
    #header-search, #header-international {
        flex: 0 1 10%;
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      #header-search {
        flex: 0 1 24%;
      }
      #header-international {
        flex: 0 1 3%;
      }
    }

    #kerrdental-nav li ul.depth-1 {
      margin-left: 15px;
    }
  }

/*Navigation*/
#kerrdental-nav li.submenu-columns ul{
  border: none;
}
#kerrdental-nav li li.last.special-inline{
  display: inline-block;
}
#kerrdental-nav li li.last{
  display: block;
}
@media (max-width: 992px) {
  #kerrdental-nav .active-d2 .depth-2 .category-matrix #matrix-container .top-tabs a{
    color: #fff !important;
  }
}
#kerrdental-nav {
  ul li {
    display: block;
    &.category-matrix{
    }
    a{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

    }
  }
  .special-inline{
    display: inline-block;
  }
  .depth-1{
    @media(max-width: 1090px) and (min-width: 992px){
      max-width: 320px;
    }

  }
  .depth-2 {
    li.link-depth-3:hover {
      background-color: #706f73;
    }
  }
  .depth-3 {
    background-color: #f0f0f0;
    color: #13457c;
    > li{
      background-color: #f0f0f0;
      color: #13457c;
      border-bottom: 1px solid #ccc;
      a{
        background-color: #f0f0f0;
        color: #333; /*Overriding important style in style.css for #kerrdental-nav li.active-d2 a*/
      }
      &:hover{
        @media (min-width: 992px) {
          background-color: #706f73;
          a {
            color: #fff !important;
          }

        }
      }

    }
  }
  > li{
    @media (min-width: 992px){
      &:hover{
        background-color: #706f73;
      }
    }
  }
  .depth-2 {
    background:  #adb0b5;
    @media(max-width: 1090px) and (min-width: 992px){
      //max-width: 320px;
    }
    > li {
      border-bottom: 1px solid #fff;
      &:hover {
        background-color: #706f73;
      }
      > a {
        background:  #adb0b5;
      }
    }
  }
}

  #kerrdental-nav li ul li:hover > a, #kerrdental-nav li ul li.active-d2 > a.menutype-drop:hover,
  #kerrdental-nav #show-product-categories > a:hover,
  #kerrdental-nav .depth-1 .submenu-columns > a:hover,
  #kerrdental-nav #specialty-dentist:hover,
  #kerrdental-nav #specialty-endo:hover,
  #kerrdental-nav #specialty-hygen:hover,
  #kerrdental-nav #specialty-lab:hover{
    @media (min-width: 992px) {
      background-color: #706f73;
    }
  }
@media (max-width: 991px) and (min-width: 319px){
  header {
    font-family: Helvetica,Arial, sans-serif;
  }
  #header-top nav{
    max-width: 490px;
    z-index: 1000;
  }
  #kerrdental-nav ul li a{
    font-size: 16px;
    letter-spacing: 0.3px;
    //padding: 10px 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

  }
  #kerrdental-nav li ul.depth-2{
    background: #bbbbbb;
  }
  #kerrdental-nav ul > li.active a, #kerrdental-nav ul > li a {
    padding: 10px 40px 10px 30px;
  }
  #kerrdental-nav ul.depth-1 > li > ul.depth-2 > li > a{
    padding: 10px 40px 10px 50px;
  }
  #kerrdental-nav ul.depth-1 > li > ul.depth-2 > li > .depth-3 > li > a{
    padding: 10px 40px 10px 70px;
  }
  #kerrdental-nav li ul.depth-2 li.leaf a{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  #kerrdental-nav li ul li a.menutype-drop:after{
    font-size: 0.9em;
  }
  #kerrdental-nav{
    //border: 1px solid black;
    height: auto !important;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.15);
  }
}

#home-mid .right a,
.select2-container--default .select2-selection--single .select2-selection__arrow,
.select2-container--default .select2-results__option--highlighted[aria-selected], .btn-primary, .btn-info,
#howtobuy-header h2 span, #resource-center-general-search form button, #specialty-procedures .more i,
#specialty-product-lines .line-content a, .node-product #product-featured .inner-wide-right, .pagination > .active > a,
.pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover,
.pagination > .active > a:focus, .pagination > .active > span:focus {
  background-color: #5d9632;
  font-family: Helvetica, sans-serif;
}


#sliding-popup {
  .popup-content {
    background-color: #706f73;
  }
}

// Learning Center and Promotions page
article.learningcenter,
article.promotions-page {
  padding: 0 5%;

  @include breakpoint($screen-sm) {
    padding: 0 50px;
  }

  .extend-bg-container {
    position: relative;
    z-index: 0;

    .extend-bg {
      min-height: 270px;
      background-size: cover;
      width: 100%;
      background-position: center;
    }
  }

  .header {
    min-height: 270px;
    position: relative;
    z-index: 1;
    max-width: 100%;
    padding: 20px 5% 40px;

    @include breakpoint($screen-sm) {
      max-width: 50%;
      padding: 20px 50px 40px;
    }

    @include breakpoint($screen-lg) {
      max-width: 70%;
    }

    h1 {
      font-family: "PlutoSansLight", Verdana, sans-serif;
      font-size: 42px;
      font-weight: 300;
      line-height: 45px;
      display: inline-block;
      position: relative;

      @include breakpoint($screen-lg) {
        font-size: 35px;
        line-height: 1;
      }

      &:before {
        content: '';
        position: absolute;
        bottom: -5px;
        top: auto;
        right: 0;
        left: auto;
        display: block;
        height: 1px;
        width: 9999%;
        background: #333;
      }
    }
  }

  .field-name-body {
    margin: 50px 0 30px;

    p {
      font-family: 'PlutoSansLight';
    }
  }
}

.view-learning-center,
.view-promotions-page {
  .view-filters {
    display: none;
  }

  #term-filters {
    padding: 30px 0;

    .filter-item {
      margin-bottom: 5px;

      &.active {
        color: #ffffff;
        background-color: #706f73;
      }
    }
  }

  .filters-wrap {
    .term-filter {
      a {
        display: inline-block;
        border: 1px solid #c6c6c6;
        padding: 8px;
        opacity: 0.8;
        color: #333333;
        margin-right: 5px;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
        font-family: "PlutoSansCondMedium", Verdana, sans-serif;
        text-decoration: none;

        &:hover {
          color: #ffffff;
          background-color: #706f73;
        }
      }
    }
  }

  .search-wrap {
    position: relative;

    .refresh-container {
      position: absolute;
      margin-right: 0;
      top: 8px;
      left: -20px;
    }
  }

  .filter-search  {
    position: relative;
    margin-right: 0;

    .form-control {
      border-radius: 3px;
      border: 1px solid #c6c6c6;
      color: #444444;
      font-size: 14px;
      font-weight: 300;
      line-height: 26px;
      padding: 6px 52px 6px 12px;
    }

    .search {
      position: absolute;
      width: 34px;
      height: 34px;
      top: 0;
      bottom: 0;
      right: 0;
      left: auto;
      line-height: 1;
      display: block;
      text-align: center;
      background-color: #5d9632;
      border-bottom-right-radius: 3px;
      border-top-right-radius: 3px;
      color: #ffffff;

      i {
        line-height: 34px;
        display: inline-block;
      }
    }
  }

  .view-empty {
    padding: 0 0 50px;

    @include breakpoint($screen-sm) {
      padding: 0 30px 50px;
    }

    @include breakpoint($screen-md) {
      padding: 0 65px 50px;
    }
  }
}

.view-learning-center {
  #term-filters {
    padding: 20px 0 15px;
  }

  .filter-search {
    background: rgba(#b6bdc5, 0.39);
    padding: 18px;

    .search {
      font-size: 22px;
    }
  }

  .search-wrap {
    max-width: 535px;
    margin: 0 auto;
  }

  .search-results-summary {
    padding: 30px 0 10px;

    @include breakpoint($screen-sm) {
      padding: 30px 30px 10px;
    }

    @include breakpoint($screen-md) {
      padding: 30px 65px 10px;
    }
  }

  .view-empty {
    @include breakpoint($screen-sm) {
      padding: 0 30px 50px;
    }

    @include breakpoint($screen-md) {
      padding: 0 65px 50px;
    }
  }

  .filters-wrap {
    @include breakpoint($screen-sm) {
      padding: 0 30px;
    }

    @include breakpoint($screen-md) {
      padding: 0 65px;
    }

    .term-filter {
      border-bottom: 1px solid rgba(#706f73, 0.38);
      padding-bottom: 15px;
    }
  }

  .view-content {
    @include breakpoint($screen-sm) {
      padding: 0 30px;
    }

    @include breakpoint($screen-md) {
      padding: 0 65px;
    }
  }
}

.lc-row {
  margin: 0 -15px;
  padding: 15px;

  .lc-item-wrapper {
    border-bottom: 1px solid rgba(#706f73, 0.38);
    padding: 0 0 30px 0;
    position: relative;

    @media screen and (min-width: 600px) and (max-width: 767px) {
      padding: 0 0 80px 0;
    }

    @include breakpoint($screen-sm) {
      padding: 0 130px 30px 30px;
    }
  }

  &.views-row-last {
    .lc-item-wrapper {
      border-bottom: none;
    }
  }

  .image.views-fieldset {
    width: 145px;
    padding: 15px;

    @media screen and (min-width: 600px) {
      width: 132px;
      float: left;
      padding: 8px 15px 0 0;
    }

    a {
      display: block;
    }

    img {
      margin: 0;
      transition: all 0.5s;
      border: 1px solid #c6c6c6;

      &:hover {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
      }
    }
  }

  .content.views-fieldset {
    padding: 0 15px;

    @media screen and (min-width: 600px) {
      width: calc(100% - 132px);
      float: left;
    }

    .views-field {
      .views-label,
      .field-content {
        display: inline-block;
      }

      .views-label {
        margin-right: 10px;
        font-weight: 700;
      }

      .field-content {
        font-family: $font-light2;
        font-weight: 300;
      }
    }
  }

  .views-fieldset {
    .views-field {
      margin-bottom: 5px;
      color: #141e28;
      word-break: break-all;

      &.views-field-title {
        margin-bottom: 10px;
        font-family: $font-light2;
        font-weight: 300;
        font-size: 17px;
        color: #141e28;

        a {
          font-family: $font-light2;
          font-weight: 300;
          font-size: 17px;
          color: #141e28;
        }
      }

      &.views-field-body {
        font-family: $font-light2;
        font-weight: 300;
      }

      &.views-field-field-resource-download-url {
        a {
          font-family: $font-light2;
          font-weight: 300;
        }
      }
    }
  }

  .views-field-field-resource-image {
    img {
      max-width: 100%;
      height: auto;
    }
  }

  .cta-links {
    padding: 5px 15px 0;

    @media screen and (min-width: 600px) and (max-width: 767px) {
      padding: 0;
      display: inline-block;
      position: absolute;
      top: auto;
      bottom: 25px;
      left: 148px;
      right: auto;
      z-index: 1;
    }

    @include breakpoint($screen-sm) {
      display: inline-block;
      position: absolute;
      padding: 0;
      top: 0;
      bottom: auto;
      left: auto;
      right: 30px;
    }
  }

  .views-field-field-resource-download-url,
  .views-field-field-hubspot-download-form {
  //  padding: 5px 15px 0;
  //
  //  @media screen and (min-width: 600px) and (max-width: 767px) {
  //    padding: 0;
  //    display: inline-block;
  //    position: absolute;
  //    top: auto;
  //    bottom: 30px;
  //    left: auto;
  //    right: 0;
  //    z-index: 1;
  //  }
  //
  //  @include breakpoint($screen-sm) {
  //    display: inline-block;
  //    position: absolute;
  //    padding: 0;
  //    top: 0;
  //    bottom: auto;
  //    left: auto;
  //    right: 30px;
  //  }
  //
    a {
      color: #706f73;
      text-decoration: none;
      margin-bottom: 5px;
    }
  }

  .more-info-link {
    &:before {
      content: '\f05a';
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      transform: translate(0,0);
      margin-right: 4px;
      color: #919191;
    }

    a {
      color: #919191;
      text-decoration: none;
    }
  }
}

.view-promotions-page {
  padding: 0 0 30px;

  #term-filters {
    .filters-wrap {
      @include breakpoint(600px) {
        width: calc(100% - 200px);
      }
    }
  }

  .filter-search {
    width: 200px;
  }

  .view-empty {
    padding: 0 0 50px;
  }

  .pr-row {
    margin-bottom: 5px;

    .views-field-title {
      background: #fafafb;

      .thumbnail-wrapper {
        height: 50px;
        width: 50px;
        display: none;

        @include breakpoint(600px) {
          float: left;
          display: flex;
          justify-content: center;
        }

        img {
          align-self: center;
          max-height: 100%;
          width: auto;
        }
      }

      .field-content {
        display: block;
      }

      .accordion-title {
        min-height: 50px;
        display: block;
        float: left;
        padding: 10px 15px;
        font-size: 18px;
        line-height: 30px;
      }

      .accordion-title-wrapper {
        display: block;
        position: relative;
        padding: 5px 15px;
        zoom: 1;

        &:before,
        &:after {
          content: "";
          display: table;
        }
        &:after {
          clear: both;
        }
      }

      .fa {
        position: absolute;
        top: calc(50% - 15px);
        right: 15px;
        left: auto;
        bottom: auto;
        z-index: 1;
        font-size: 30px;
        color: #1c2b39;
      }

      span[aria-expanded=true] {
        .fa{
          -webkit-transform: rotate(-180deg);
          -moz-transform: rotate(-180deg);
          -ms-transform: rotate(-180deg);
          -o-transform: rotate(-180deg);
        }

      }
    }

    .promotion-wrapper {
      border-top: 2px solid #e1e1e1;
      padding: 25px 0;
    }

    .views-field-body {
      padding: 0 0 10px;
    }

    .views-field-field-promotion-disclaimer {
      padding-top: 15px;
      padding-bottom: 15px;

      @include breakpoint($screen-sm) {
        float: left;
      }
    }
  }
}

.gigya-layout-cell.responsive.with-social-login,
.gigya-layout-cell.responsive.with-divider {
  display: none;
}

.gigya-layout-row.with-divider {
  display: block !important;
}

.gigya-layout-cell.responsive.with-site-login{
  display: block !important;
  float: none !important;
  margin: 0 auto !important;

  h2.title {
    color: #4e515e;
    font-size: 20px;
  }

  .gigya-input-submit {
    background-color: #416da9;
    color: #ffffff;
    font-size: 17px;
  }

  a {
    color: #416da9;
  }

  .gigya-composite-control-dropdown {
    > label {
  //    position: relative;
  //    margin-bottom: -15px;
  //    background-color: white;
  //    width: auto;
  //    float: left;
  //    margin-left: 5px;
  //    padding-left: 5px;
  //
      .gigya-label-text {
        color: #818488 !important;
        font-size: 13px !important;
      }
    }
  }
}

#gigya-raas-login-div .gigya-screen-caption {
  display: none;
}

#gigya-raas-profile-div {
  .gigya-screen-caption {
    padding-left: 68px;
    border-bottom: none;
    color: #4e515e;
    font-size: 20px;
    font-weight: 500;
  }

  .gigya-screen.portrait {
    padding-top: 20px;
  }

  .gigya-input-submit {
    background-color: #416da9;
    color: #ffffff;
    font-size: 17px;
  }

  a {
    text-decoration: underline;
    color: #416da9;
  }

  .gigya-composite-control.gigya-composite-control-dropdown {
    label {
      display: none;
    }
  }
}

.gigya-screen.landscape .gigya-layout-row .gigya-layout-cell.under-site-login {
  margin-left: 0 !important;
  float: none !important;
  display: inline-block !important;
  text-align: left !important;

  a {
    text-decoration: underline !important;
  }
}
.gigya-myPhoto-profile-box-wrapper {
  border: none !important;

  &:not(.gigya-myPhoto-status-uploaded) .gigya-myPhoto-profile-image {
    background-image: url("../img/shape.svg") !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: contain !important;
  }
}

body.page-profile {
  .alert.alert-danger {
    margin: 0 -13px 0px -15px;
    border-radius: 0 !important;
    background-color: #f8f8f8;
    border: none;
    color: #4e515e;
    font-size: 14px;
    font-weight: bold;
    font-family: 'PlutoSansCond', sans-serif;

    &:before {
      content: url("../img/shape.png");
      display: block;
      margin-bottom: -10px;
      float: left;
      margin-right: 5px;
    }
  }
}

body.page-cart {
  .alert.alert-success {
    margin: 0 -13px 0px -15px;
    border-radius: 0 !important;
    background-color: #c4d604;
    border: none;
    color: white;
    font-size: 14px;
    font-weight: bold;
    font-family: 'PlutoSansCond', sans-serif;
  }
}

body.page-profile.admin-menu section.col-sm-12,
body.page-cart.admin-menu section.col-sm-12 {
  margin-top: 0;
}

.form-actions.commerce-line-item-actions {
  #edit-submit {
    background-color: #f8f8f8;
    color: #4e515e;
    font-size: 17px;
    padding: 8px 30px;
    margin-bottom: 10px;
  }

  #edit-continue {
    background-color: #f8f8f8;
    border: none;
    color: #4e515e;
    font-size: 17px;
    padding: 8px 30px;
    margin-left: 5px;
    margin-bottom: 10px;
  }

  #edit-checkout {
    font-size: 17px;
    padding: 8px 30px;
    margin-bottom: 10px;
    margin-left: 5px;
  }
}

.page-customer-orders {
  .page-header {
    margin-top: 20px;
    border-bottom: none;
  }
  .view-commerce-user-orders {
    table {
      thead {
        th {
          background-color: #f0f0f0;
          color: #4e515e;
          border-bottom: none;

          a {
            color: #4e515e;
          }
        }
      }
      tbody {
        border-bottom: 1px solid rgba(0,0,0,0.3);
        tr {
          td {
            padding: 10px 8px;
            background-color: white;
            border-top: none;
          }
          &.even {
            td {
              background-color: #fafafa;
            }
          }
        }
      }
    }
  }
}

.page-user-orders {
  .view-commerce-line-item-table {
    margin-top: 20px;
    table {
      margin-bottom: 0;
      thead {
        th {
          background-color: #f0f0f0;
          color: #4e515e;
          border-bottom: none;

          a {
            color: #4e515e;
          }
        }
      }
      tbody {
        border-bottom: 1px solid rgba(0,0,0,0.3);
        tr {
          td {
            padding: 10px 8px;
            background-color: white;
            border-top: none;
          }
          &.even {
            td {
              background-color: #fafafa;
            }
          }
        }
      }
    }
  }

  .commerce-price-formatted-components {
    tbody tr td {
      background-color: transparent !important;
    }
  }
  .field-name-commerce-order-total .commerce-price-formatted-components tr.component-type-commerce-price-formatted-amount {
    background-color: transparent;

    td {
      border-top: none;

      &.component-title {
        text-align: right;
      }
    }
  }

  .field-name-commerce-customer-billing {
    color: #706f73;
    font-size: 16px;
    letter-spacing: 0.3px;

    > .field-label {
      margin-bottom: 20px;
    }
  }
}

.gigya-myPhoto-profile-box-wrapper .gigya-myPhoto-status-icon {
  display: none;
}

.field-name-commerce-customer-billing {
  background-color: #f8f8f8;
  margin: 0 -15px;
  padding: 20px 30px;
}

.page-user-orders .legal-notice {
  background-color: #f8f8f8;
  margin: 0 -15px;
  padding: 20px 30px 40px;
}

.entity-commerce-customer-profile {
  .group {
    margin-bottom: 20px;
    position: relative;

    &.group-user:before {
      font-family: FontAwesome;
      content: "\f007";
      position: absolute;
      left: 0;
    }

    &.group-address:before {
      font-family: FontAwesome;
      content: "\f095";
      position: absolute;
      left: 0;
    }

    &.group-contact:before {
      font-family: FontAwesome;
      content: "\f041";
      position: absolute;
      left: 0;
    }

    &.group-dealers:before {
      font-family: FontAwesome;
      content: "\f07a";
      position: absolute;
      left: 0;
    }

    .field {
      margin-left: 10px;

      .field-label {
        width: 30%;

        @media (max-width: 480px) {
          width: 50%;
        }
      }
    }
  }

  .fieldset {
    .field {
      .icon {
        margin-left: -25px;
        margin-right: 10px;
      }
    }
  }
}

#gigya-login-page-content {
  @media (min-width: 992px) {
    margin-top: 50px;
  }

  @media (max-width: 992px) {
    #content-wrapper {
      text-align: center;

      #content {
        display: inline-block;
        text-align: left;
      }
    }
  }
}

#term-header {
  float: none;
}

#term-products {
  h4 {
    @media (max-width: 480px) {
      font-size: 20px;
    }
  }

  .view-filters {
    ul {
      margin-bottom: 10px;
    }
  }
}

@media (min-width: 991px) {
  #gigya-profile-page-content #content-wrapper {
    float: right;
  }
}

.profile-status {
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 2px;
  border: 1px solid #ccc;
  padding: 20px 26px;

  h2 {
    font-size: 20px;
    line-height: 1;
    font-family: "gotham_medium";
    margin: 0 0 20px;
    padding: 0 0 10px;
    border-bottom: 1px solid #313131;
  }
}

.node-type-enhanced-page {
  #aboutus-header .header-image-responsive {
    background: none;

    @media (max-width: 991px) {
      height: auto;
    }

    &:after {
      padding-top: 0;
    }
    @media (max-width: 991px) {
      img {
        display: block;
      }
    }
    @media (min-width: 992px) {
      &.no-image {
        height: 0;
        &:before {
          height: 0;
        }
      }

      img {
        display: none;
      }
    }
  }
}

.node-type-landing-page {
  #aboutus-header .header-image-responsive.no-image {
    background: none;
    height: 0;
    &:before {
      height: 0;
    }
  }
}

body.domain-ww2-kavo-com-ru-ru {
  *:not(.fa),
  #home-mid .right a,
  #product-groups-slider ul.slides li .text h2,
  .field-name-field-product-group-blocks .field-collection-item-field-product-group-blocks .product-group-block-title,
  #kerrdental-nav ul.depth-1>li>a,
  #kerrdental-nav #matrix-container .right-contents .content-holder .info .header,
  #kerrdental-nav #matrix-container .right-contents .content-holder .see-all a.btn,
  #term-products h4, #term-subcategory-links h4,
  a.product-listing span.subtitle,
  #product-category-buttons a,
  .node-product #product-top-right .title-category a,
  #category-expand-boxes a,
  .node-product #product-top-right h2,
  .node-product #product-related h3,
  #header-breadcrumbs .breadcrumb>li>a,
  #resource-center-quick-search h3,
  .block-facetapi h2,
  #block-kerrdental-search-sort-search-block h2,
  #block-kerrdental-search-items-per-page-block label
  {
    font-family: 'Montserrat-Regular', sans-serif;
  }
}

//Style for Pelton
.no-padding {
  padding: 0;
}

#home-mid {
  color: #706f73;
  margin-bottom: 15px;
  .right {
    //overflow: hidden;
    margin-bottom: 40px;
    h4 {
      color: #706f73;
    }
  }
}

#home-mid .home-mid-promotion .promo-links a {
  color: #5d9632;
  border-color: #5d9632;
  background: none;
  text-decoration: none;
  &:hover {
    color: #8cc63e;
    border-color: #8cc63e;
  }
}

#country-menu-cookie, #country-menu-cookie .country-menu-region {
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

.node-type-enhanced-page {
  .node-product .top-row {
    margin-top: 0;
  }
  .header-image-responsive:before {
    height: 285px;
  }
}

.node-type-product {
  .main-container {
    background: transparent;
  }
  .shadow {
    position: absolute;
    height: 100%;
    left: -100%;
    right: -100%;
    top: -20px;
    z-index: -9999;
    background-image: linear-gradient(180deg, #f0f0f0 0%, #ffffff 30%);
  }
  .flexslider {
    background: none;
    .slides {
      display: flex;
      align-items: center;
    }
  }
}

.features {
  overflow: hidden;
  margin-top: 30px;
  // new styles
  @media (min-width: 992px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  // new styles end
  .left , .right {
    margin-bottom: 40px;
    div {
      overflow: hidden;
    }
    .img-wrap {
      @media (min-width: 992px) {
        float: left;
        margin-bottom: 0;
      }
      max-width: 235px;
      max-height: 235px;
      text-align: center;
      background-color: #f7f7f7;
      overflow: hidden;
      margin: 0 auto 20px;
      img {
        width: 100%;
        max-width: 100%;
        height: auto;
      }
    }

    .text-wrap {
      @media (min-width: 992px) {  // new styles
        padding-left: 50px; // new styles
      }  // new styles
      //padding-left: 20px;

      h3 {
        color: #4a4a4a; // new styles, changed color.
        font-family: "Helvetica Neue LT Std Lt", Arial, sans-serif;
        font-size: 30px;
        line-height: 33px;
        margin: 0 0 15px 0;
      }
      p {
        @media (min-width: 992px) {
          max-width: 500px;
        }
        font-family: "Helvetica Neue LT Std Lt", Arial, sans-serif;
        font-size: 16px;
        letter-spacing: 0.3px;
        font-weight: 400;
        line-height: 19px;
      }
      ul {
        @media (min-width: 992px) {
          max-width: 500px;
        }
        color: #706f73;
        font-family: "Helvetica Neue LT Std Lt", Arial, sans-serif;
        font-size: 16px;
        letter-spacing: 0.3px;
        font-weight: 400;
        line-height: 19px;
      }
    }
  }
  .right {
    @media (min-width: 992px) {
      .img-wrap {
        float: right; // changes left to right.
      }

      .text-wrap {
      padding-left: 0; // new styles padding 0.
        p {
          max-width: 650px; // new styles
        }
      }
    }
  }
      //@media (min-width: 992px) {
  //  .left {
  //    width: 60%;
  //    float: left;
  //  }
  //  .right {
  //    width: 60%;
  //    float: right;
  //  }
  //}

}

.info-colors {
  .info-section-content {
    overflow: hidden;
    @media (max-width: 1199px) {
      display: flex;
      flex-direction: column;
    }
    .color-tab, .color-show-wrap {
      width: 100%;
    }
    @media (min-width: 1199px) {
      .color-tab {
        width: 66.66666667%;
        float: left;
      }
      .color-show-wrap {
        width: 33.33333333%;
        float: left;
      }
    }
  }
  .btn-wrap {
    text-align: center;
    .field-item a {
      min-width: 225px;
      padding: 10px 20px;
      color: #ffffff;
      font-size: 16px;
      letter-spacing: 0.3px;
      font-weight: 700;
      line-height: 23px;
      background-color: #5d9632;
      font-family: Helvetica, sans-serif;
      &:hover {
          text-decoration: none;
          background-color: #8cc63e;
      }
    }
  }

}

.color-tab {
  overflow: hidden;
  padding: 0;
  h3 {
    color: #706f73;
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    margin-left: 5px;
    margin-top: 0;
  }
  ul {
    font-size: 0;
    padding: 0;
    margin-bottom: 45px;
    @media (max-width: 1199px) {
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      padding-bottom: 15px;
    }
  }
  li {
    position: relative;
    display: inline-block;
    width: 81px;
    height: 81px;
    margin: 5px;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    border: 4px solid transparent;
    img {
      border-radius: 6px;
      width: 100%;
    }
  }
  @media (max-width: 991px) {
    .scroll-container {
      overflow: hidden;
    }
  }
}

.error-form-wrap {
  background-color: #f4f4f4;
  padding: 15px;
  input{
    font-family: Helvetica, Arial, sans-serif;
    max-width: 520px;
  }
}

.sticky-color {
  position: relative;
}
.sticky-color .fixed {
  position: fixed;
  display: block;
  margin-left: 23px;
}
.sticky-color .at-bottom {
  position: absolute;
  bottom: 0;
  left: 38px;
  margin-bottom: 55px;
}

.color-show-wrap {
  text-align: center;
  @media (min-width: 1200px) {
    padding: 0;
    text-align: right;
  }
}
.color-show {
  width: 325px;
  height: 362px;
  @media (max-width: 500px) {
    width: 100%;
    height: auto;
  }
  padding: 20px;
  display: inline-block;
  margin-bottom: 30px;
  .color-img-show {
    height: 287px;
    border-radius: 14px;
    background-color: #e0d9d1;
    transition: 500ms;
    background-size: cover;
  }
  .color-name {
    color: #706f73;
    font-family: "Helvetica Neue";
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    margin-top: 16px;
  }
}

#info-colors .color-tab-sidebar {
  .color-tab, .color-show-wrap {
    width: 100%;
    float: none;
  }
}

#info-colors .color-tab-sidebar .fixed {
  margin-left: 44px;
}
#info-colors .color-tab-sidebar .at-bottom {
  left: 59px;
}

#info-colors .sticky-color {
  display: flex;
  .color-show-wrap {
    text-align: center;
  }
}

.checked {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
  &:before {
    content: url("../img/pelton/checkmark.svg");
    position: absolute;
    left: 50%;
    top: 26px;
    transform: translateX(-50%);
  }
}

.resource-center-page {
  .resource-list {
    margin-bottom: 40px;
  }
  .block-title {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

// Landing page styles
.node-type-landing-page, .header-page-2  {
  h1 {
    display: inline-block;
    padding: 25px 0 0 0;
    margin: 0;
    color: #4a4a4a;
    font-family: "Helvetica Neue LT Std Lt", Arial, sans-serif;
    font-size: 30px;
    position: relative;
    word-break: keep-all;
    -webkit-hyphens: none;
    min-width: 430px;
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 500%;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: #adafb1;
    }
  }
  .relative .row {
    padding: 20px 0;
    h3 {
      color: #4a4a4a;
      font-family: "Helvetica Neue LT Std Lt", Arial, sans-serif;
      font-size: 30px;
      line-height: 33px;
      margin: 40px 0 5px 0;
    }
    p,
    ul li {
      color: #706f73;
      font-family: "Helvetica Neue", Arial, sans-serif;
      font-size: 16px;
      letter-spacing: 0.3px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 20px;
      @media (min-width: 992px) {
        margin-right: 30px;
      }
    }
    ul {
      padding-left: 15px;
    }
    p {
      padding-top: 10px;
    }
    .btn {
      min-width: 225px;
      margin-right: 20px;
      margin-bottom: 10px;
    }
  }
  .under-header-text {
    position: relative;
    margin-top: 15px;
    max-width: 500px;
    color: #706f73;
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-size: 16px;
    letter-spacing: 0.3px;
    font-weight: 400;
    line-height: 20px;
    h3 {
      color: #4a4a4a;
      font-family: "Helvetica Neue", Arial, sans-serif;
      font-size: 20px;
      font-weight: 400;
      line-height: 23px;
    }
  }
  .bottom-text-image {
    padding: 0 15px;
    table {
      tr:nth-child(odd) {
        background-color: #f9f9f9;
      }
      caption {
        font-family: "Helvetica Neue LT Std Lt", Arial, sans-serif;
        padding-bottom: 20px;
        color: #4a4a4a;
        font-weight: 400;
        font-size: 30px;
        line-height: 33px;
      }
      td {
        padding: 10px 50px 10px 25px;
        color: #706f73;
        font-size: 16px;
        letter-spacing: 0.3px;
        font-weight: 400;
        line-height: 20px;
        &:first-child {
          white-space: nowrap;
        }
        @media (min-width: 1200px) {
          padding: 10px 50px 10px 25px;
        }
      }
      & + p {
        margin-left: 25px;
      }
    }
    p {
      font-size: 13px;
    }
  }
  .row-container-2,.row-container-4, .row-container-6, .row-container-8 {
    background-color: #f0f0f0;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      height: 100%;
      width: 100%;
      bottom: 0;
      left: -100%;
      background-color: #f0f0f0;
    }
    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      height: 100%;
      width: 100%;
      bottom: 0;
      right: -100%;
      background-color: #f0f0f0;
    }
  }
  .custom-text-block {
    background-color: #f0f0f0;
    position: relative;
    padding: 15px 35px;
    &:before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      height: 100%;
      width: 100%;
      bottom: 0;
      left: -100%;
      background-color: #f0f0f0;
    }
    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      height: 100%;
      width: 100%;
      bottom: 0;
      right: -100%;
      background-color: #f0f0f0;
    }
    .wrap {
      @media (min-width: 992px) {
        display: flex;
      }
      .text {
        @media (min-width: 992px) {
          width: 50%;
        }
        p {
          @media (min-width: 992px) {
            max-width: 430px;
          }
          color: #706f73;
          font-family: "Helvetica Neue", Arial, sans-serif;
          font-size: 16px;
          letter-spacing: 0.3px;
          font-weight: 400;
          line-height: 20px;
          margin-bottom: 20px;
        }
        @media (min-width: 991px) and (max-width: 1200px) {
          padding-right: 20px;
        }
      }
      .item {
        text-align: left;
        @media (min-width: 992px) {
          width: 50%;
          text-align: left;
            iframe {
                max-width: 535px;
                max-height: 282px;
            }
        }
        @media (max-width: 991px) {
          position: relative;
          overflow: hidden;
          padding-top: 56.25%;
          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
          }
        }
        @media (min-width: 991px) and (max-width: 1200px) {
          position: relative;
          overflow: hidden;
          padding-top: 26%;
          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
          }
        }

      }
    }
  }
  .header-image-responsive {
    min-height: 280px;
    align-items: normal;
  }
  .header-image-responsive:before{
    content: '';
    position: absolute;
    width: 200%;
    right: -50%;
    min-height: 280px;
    z-index: -1;
    background-position-x: center;
  }

  .bottom-link {
    margin: 30px 0;
  }
}
.node-type-landing-page  {
  .left-content-body  .field-item {
    float: left;
  }
}
.node-type-landing-page {
  .product-box {
    margin-bottom: 20px;
    padding-top: 20px;
    a {
      display: block;
      text-decoration: none;
    }
    .box-img {
      min-height: 260px;
      vertical-align: middle;
      background-color: #f8f8f8;
      line-height: 260px;
      img {
        max-width: 100%;
        height: auto;
      }
    }
    .box-title {
      background-color: #f0f0f0;
      padding: 15px 20px;
      color: #706f73;
      font-family: Helvetica, Arial, sans-serif;
      font-size: 20px;
      font-weight: 400;
      line-height: 23px;
      text-align: left;
    }
    .in {
      position: relative;
    }
    i {
      position: absolute;
      right: 0;
      color: #5d9632;
    }
  }
}

.bottom-link {
  margin-bottom: 30px;
}
.bottom-buttons-links {
  padding: 40px 0 20px;
  background-color: #f8f8f8;
  position: relative;
  a {
    display: inline-block;
    margin: 0 20px 20px 0;
    border: 1px solid #5d9632;
    color: #5d9632;
    background-color: transparent;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 16px;
    letter-spacing: 0.3px;
    font-weight: 700;
    line-height: 23px;
    text-decoration: none;
    padding: 10px 30px;
    @media (max-width: 767px) {
      text-align: center;
    }
    @media (min-width: 767px) and (max-width: 991px) {
      padding: 10px 20px;
    }
      &:hover {
      color: #8cc63e;
      border-color: #8cc63e;
      background-color: transparent;
    }
  }
  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    height: 100%;
    width: 100%;
    bottom: 0;
    left: -100%;
    background-color: #f8f8f8;
  }
  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    height: 100%;
    width: 100%;
    bottom: 0;
    right: -100%;
    background-color: #f8f8f8;
  }
  .field-item {
    display: inline-block;
  }
}

// Line under product category title.
.vocabulary-product-categories .line {
  position: relative;
  margin: 0 0 15px 0;
}

.vocabulary-product-categories .line-inner {
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  left: -400%;
  width: 600%;
  border-bottom: 1px solid #adafb1;
}

@media (min-width: 768px) {
  .node-product .line-inner {
    right: -100%;
    width: 200%;
    }
}


//Practice profile
.kerr-page-practice-profiles {
  .node {
    margin-bottom: 50px;
  }
  .extend-bg-container {
    margin-bottom: 50px;
    @media (max-width: 767px) {
      margin-left: -15px !important;
    }
  }
  .extend-bg {
    height: 180px;
    padding-top: 60px;
    background-position: center;
  }
  h1 {
    position: relative;
    display: inline-block;
    color: #4a4a4a;
    font-family: "Helvetica Neue LT Std Lt", Arial, sans-serif;
    font-size: 30px;
    line-height: 33px;
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 500%;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: #adafb1;
    }
  }
}
.node-type-practice-profile {
  h1 {
    margin-bottom: 10px;
  }
  .practice-location {
    margin-bottom: 20px;
  }
}
.practice-location {
  color: #adafb1;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 13px;
  line-height: 13px;
  margin: 0;
}
.profile-item {
  margin-bottom: 30px;
  background-color: #f8f8f8;
  overflow: hidden;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column-reverse;
  }
  @media (min-width: 992px) {
    align-items: center;
  }
  .profiles-info {
    padding: 50px 70px;
    @media (max-width: 991px) {
      overflow: hidden;
      padding: 45px 15px;
    }
    .photo {
      padding: 0;
      margin-bottom: 15px;
      a {
        display: block;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 auto;
      }
      img {
        margin: 0 auto;
      }
    }
    h3 {
      color: #4a4a4a;
      font-family: "Helvetica Neue LT Std Lt", Arial, sans-serif;
      font-size: 30px;
      line-height: 33px;
      margin: 0;
    }
    .teaser-title-wrap {
      border-bottom: 1px solid #adafb1;
    }
    p {
      font-size: 16px;
      letter-spacing: 0.3px;
      line-height: 20px;
      @media (max-width: 991px) {
        font-size: 26px;
        line-height: 35px;
      }
    }
    .teaser {
      color: #4a4a4a;
      font-family: "Helvetica Neue", Arial, sans-serif;
      font-size: 16px;
      letter-spacing: 0.3px;
      font-weight: 400;
      line-height: 20px;
    }
    .btn {
      font-size: 16px;
      letter-spacing: 0.3px;
      font-weight: 700;
      line-height: 23px;
      padding: 8px 70px;
      @media (max-width: 991px) {
        font-size: 28px;
        font-weight: 700;
        line-height: 46px;
        padding: 14px 71px;
      }
    }
  }
  .image {
    padding: 0;
  }
  .practice-location {
    margin: 5px 0;
    @media (max-width: 991px) {
      font-size: 20px;
      margin: 10px 0;
    }
  }
}

.practice-testimonial {
  overflow: hidden;
  display: flex;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  color: #706f73;
  font-family: "Helvetica Neue LT Std", Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0.3px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  .img {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f8f8;
    padding: 35px 0 40px 0;
    img {
      border-radius: 50%;
    }
    @media (max-width: 767px) {
      padding: 15px 0;
    }
  }
  .text {
    padding: 35px 20px 40px 20px;
    h3 {
      color: #4a4a4a;
      font-size: 20px;
      line-height: 23px;
    }
  }
}
.node-practice-profile {
  margin-bottom: 50px;
  p {
    font-family: "Helvetica Neue", Arial, sans-serif;
  }
  li {
    color: #706f73;
    font-size: 16px;
    letter-spacing: 0.3px;
    line-height: 20px;
    font-family: "Helvetica Neue", Arial, sans-serif;
    margin-bottom: 10px;
  }
  h3 {
    color: #4a4a4a;
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
  }
  .p-bg {
    position: relative;
    padding-top: 20px;
    background-color: #f8f8f8;
    &:before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      height: 100%;
      width: 100%;
      bottom: 0;
      left: -100%;
      background-color: #f8f8f8;
    }
    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      height: 100%;
      width: 100%;
      bottom: 0;
      right: -100%;
      background-color: #f8f8f8;
    }
  }
}
.practice-sidebar {
  @media (max-width: 991px) {
    padding: 0;
  }
  h2 {
    color: #4a4a4a;
    font-size: 20px;
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-weight: 400;
    line-height: 23px;
    margin: 0;
    padding-bottom: 10px;
  }
  .field-collection-container {
    border-bottom: 0;
  }
  .field-collection-view {
    margin: 0;
    padding: 0 10px;
    border-bottom: 1px solid #adafb1;
  }
  .doctors-list {
    border: 1px solid #f0f0f0;
    margin-bottom: 10px;
    h2 {
      padding-top: 10px;
      padding-left: 40px;
      border-bottom: 1px solid #f0f0f0;
    }
    .views-row {
      font-size: 16px;
      letter-spacing: 0.3px;
      border-bottom: 1px solid #f0f0f0;
      span {
        display: block;
      }
      a {
        display: block;
        padding: 14px 0 14px 50px;
      }
      .active {
        text-decoration: none;
      }
    }
  }
  .design-tools {
    padding: 30px 40px 50px 40px;
    background-color: #f8f8f8;
    .help-text {
      color: #adafb1;
      font-family: "Helvetica Neue", Arial, sans-serif;
      font-size: 13px;
      line-height: 13px;
      margin-bottom: 5px;
    }
    .btn {
      width: 100%;
      margin-bottom: 15px;
    }
  }
  .active {
    background-color: #f8f8f8;
    color: #4a4a4a;
  }
}
.pelton-slider {
  margin: 0 0 20px;
  .flex-direction-nav {
    a {
      width: 42px;
      height: 54px;
      margin: -20px -10px 0;
    }
  }
  .flex-direction-nav a.flex-next:before {
    content: url("../img/pelton/arrow_r_slider.svg");
  }
  .flex-direction-nav a.flex-prev:before {
    content: url("../img/pelton/arrow_l_slider.svg");
  }
  .flex-control-nav {
    bottom: 20px;
    z-index: 2;
  }
  .flex-control-paging li a {
    background: #f0f0f0;
  }
  .flex-control-paging li a.flex-active {
    background: #5d9632;
  }
}

// Banner img styles.
.section-banner-img {
  position: relative;
  margin-bottom: -20px;
  overflow: hidden; // tmp styles.

  img {
    width: 100%;

  }
  // tmp styles.
  &:after {
    background-image: url("../img/img placeholder.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: -120%;
    left: 15px;
    right: 0;
    bottom: 0;
    content: '';
    min-height: 1px;
    height: auto;
    width: calc(100% - 30px);
    opacity: 0.5;
  }
}

#designgallery-header, .header-page-2 {
  min-height: 280px;
  display: block;
  h1 {
    padding: 25px 0 0 0;
    margin-top: 0;
  }
  .under-header-text {
    position: relative;
    margin-top: 15px;
    max-width: 500px;
    color: #706f73;
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-size: 16px;
    letter-spacing: 0.3px;
    font-weight: 400;
    line-height: 20px;
  }
}

.cta-btns {
  text-align: right;
  margin: 25px 0;
  .field-item {
    display: inline-block;
    margin-left: 10px;
  }
}

.design-gallery {
  margin: 0 auto 35px auto;
  @media (max-height: 768px) and (min-height: 400px) {
    max-width: 900px;
  }
  .slide {
    height: 700px;
    @media (max-height: 768px) {
      height: 500px;
    }
    @media (max-height: 768px) and (min-height: 400px) {
      max-width: 900px;
    }
    @media (max-width: 1199px) and (min-height: 769px){
      height: 600px;
    }
    @media (max-width: 991px) and (min-height: 769px) {
      height: 400px;
    }
    @media (max-width: 767px) and (min-height: 769px) {
    }
    @media (max-width: 500px) and (min-height: 769px) {
      height: 300px;
    }
    position: relative;
    .bg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      z-index: 1;
      filter: blur(8px);
      -webkit-filter: blur(8px);
    }
    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
      background-color: rgba(0,0,0,0.6);
    }
    .img-wrap {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 3;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .slide-title {
    position: absolute;
    top: 5%;
    left: 5%;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.93);
    color: #ffffff;
    font-family: "Helvetica Neue LT Std Lt", Arial, sans-serif;
    font-size: 30px;
    line-height: 33px;
  }
  .flexslider {
    margin: 0 0 3px 0;
    border: 0;
  }
  .flexslider:hover .flex-direction-nav .flex-prev{
    top: 20px;
    bottom: 0;
    left: 0;
    width: 48px;
    height: auto;
  }
  .flexslider:hover .flex-direction-nav .flex-next{
    top: 20px;
    bottom: 0;
    right: 0;
    width: 48px;
    height: auto;
  }
  .flex-direction-nav {
    opacity: 0.4;
  }

  .flex-direction-nav a.flex-next {
    background: #000000 url(../img/pelton/arr_r.svg) no-repeat center;
    &:before {
      content: '';
    }
    &:hover {
      opacity: 0.8;
    }
  }
  .flex-direction-nav a.flex-prev {
    background: #000000 url(../img/pelton/arr_l.svg) no-repeat center;
    &:before {
      content: '';
    }
    &:hover {
      opacity: 0.8;
    }
  }
  #slider {
    img {
      width: auto;
      max-width: 100%;
    }
  }
  #carousel {
    li {
      cursor: pointer;
      margin-right: 2px;
      img {
        opacity: 0.5;
      }
    }
    .flex-active-slide {
      position: relative;
      padding-bottom: 5px;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 3px;
        background-color: #5d9632;
      }
      img {
        opacity: 1;
      }
    }
    .flex-direction-nav a {
      max-height: 73px;
    }
  }
}

.pagination > li > a, .pagination > li > span,.pagination > li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus {
  color: #5d9632;
}



@media (max-width: 720px) {
  .mobile-banner {
    display: block;
  }
  .desktop-banner {
    display: none;
  }
}
@media (min-width: 721px) {
  .mobile-banner {
    display: none;
  }
  .desktop-banner {
    display: block;
  }
}
@media (max-width: 991px) {
  .desktop-banner {
   opacity: 0.5;
  }
}

//Track Order page
.track-order-page {
  .header-page-2 {
    margin-bottom: 50px;
  }
}
.forms-track {
  display: flex;
  justify-content: center;
  margin-bottom: 170px;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
  }
  .track-form {
    max-width: 420px;
    margin: 0 23px;
    display: flex;
    background-color: #f0f0f0;
    padding: 30px 0;
    margin-bottom: 20px;
    @media (max-width: 500px) {
      flex-direction: column;
      align-items: center;
      padding: 30px 25px 30px 30px;
    }
    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 170px;
      @media (max-width: 500px) {
        margin-bottom: 15px;
      }
    }
    form {
      max-width: 200px;
      margin-right: 50px;
      @media (max-width: 500px) {
        margin-right: 0;
      }
    }
    input {
      max-width: 190px;
      height: 30px;
      margin-bottom: 20px;
      padding-left: 10px;
    }
    button {
      width: 190px;
    }
    sup {
      color: red;
    }
    p {
      display: inline-block;
      font-family: Helvetica, Arial, sans-serif;
      position: relative;
    }
  }
}

.canvas-360-wrapper {
  text-align: center;
}


// Styles for image zoom icon on product page.

.node-product {
  #slider {
    .slides {
      position: relative;
    }

    .fa.fa-arrows-alt {
      position: absolute;
      right: 15%;
      bottom: 5%;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}
// Product page sliders.
.thumbnail-carousel {
  @media (max-height: 768px) and (min-height: 400px) {
    max-width: 900px;
  }
  @media (max-width: 768px) {
    .flex-direction-nav .flex-prev, .flex-direction-nav .flex-next {
      display: none;
    }
  }
  .slide {
    height: 700px;
    @media (max-height: 768px) {
      height: 500px;
    }
    @media (max-height: 768px) and (min-height: 400px) {
      max-width: 900px;
    }
    @media (max-width: 1199px) and (min-height: 769px){
      height: 600px;
    }
    @media (max-width: 991px) and (min-height: 769px) {
      height: 400px;
    }
    @media (max-width: 767px) and (min-height: 769px) {
    }
    @media (max-width: 500px) and (min-height: 769px) {
      height: 300px;
    }
    position: relative;
    .bg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      z-index: 1;
      filter: blur(8px);
      -webkit-filter: blur(8px);
    }
    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
      background-color: rgba(0,0,0,0.6);
    }
    .img-wrap {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 3;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .slide-title {
    position: absolute;
    top: 5%;
    left: 5%;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.93);
    color: #ffffff;
    font-family: "Helvetica Neue LT Std Lt", Arial, sans-serif;
    font-size: 30px;
    line-height: 33px;
  }
  .flexslider {
    margin: 0 0 3px 0;
    border: 0;
  }
  .flexslider:hover .flex-direction-nav .flex-prev{
    top: 50px;
    bottom: 0;
    left: 0;
    width: 48px;
    height: auto;
  }
  .flexslider:hover .flex-direction-nav .flex-next{
    top: 50px;
    bottom: 0;
    right: 0;
    width: 48px;
    height: auto;
  }
  .flex-direction-nav a:before {
    font-size: 60px;
  }

  /*  .flex-direction-nav a.flex-next {
      background: #000000 url(../img/arr_r.svg) no-repeat center;
      &:before {
        content: '';
      }
      &:hover {
        opacity: 0.8;
      }
    }
    .flex-direction-nav a.flex-prev {
      background: #000000 url(../img/arr_l.svg) no-repeat center;
      &:before {
        content: '';
      }
      &:hover {
        opacity: 0.8;
      }
    }*/
  #slider {
    img {
      width: auto;
      max-width: 100%;
    }
  }
  #carousel {
    li {
      cursor: pointer;
      margin-right: 2px;
      padding-bottom: 5px;
      img {
        opacity: 0.5;
      }
    }
    .flex-active-slide {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 3px;
        background-color: #8cc63e;
      }
      img {
        opacity: 1;
      }
    }
    .flex-direction-nav a {
      max-height: 100px;
    }
  }
}

.node-product .flexslider {
  border: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  margin-bottom: 0;
  padding: 0 50px;
  margin-left: -50px;
  margin-right: -10px;
}

@media (max-width: 1199px) {
  .node-product .flexslider#slider {
    &:hover .flex-direction-nav .flex-prev,
    &:hover .flex-direction-nav .flex-next {
      top: 45%;
    }
    .fa-arrows-alt {
      right: 0;
    }
  }
}

@media (max-width: 991px) {
  .node-product .flexslider#slider {
    margin: 0 !important;
    .fa-arrows-alt {
      right: 20%;
    }
  }
  .node-product .flexslider#carousel {
    //padding: 0;
    .flex-direction-nav {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  .node-product .flexslider#carousel {
    //padding: 0;
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0;
  }
}




@media (min-width: 992px) and (max-width: 1199px) {
  .node-product .flexslider#carousel {
    padding: 0;
    .flex-direction-nav {
      display: none;
    }
    .slides li {
      margin-right: 19px !important;
      width: 90px !important;
      height: 90px !important;

      img {
        width: 80px;
        height: 80px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .node-product .flexslider#carousel {
    padding: 0 70px 20px;
    margin-left: 0;
    margin-right: 0;
    .slides li {
      margin-right: 20px !important;
      width: 90px !important;
      height: 90px !important;
      img {
        width: 80px;
        height: 80px;
      }

    }
  }
}

@media (min-width: 451px) and (max-width: 767px) {
  .node-product .flexslider#carousel {
    .slides li {
      margin-right: 15px !important;
      width: 120px !important;
      height: 120px !important;
      img {
        width: 110px;
        height: 110px;
      }
    }
  }
  .node-product .flexslider#slider {
    .fa-arrows-alt {
      right: 0;
    }
  }
}

@media (min-width: 375px) and (max-width: 450px) {
  .node-product .flexslider#carousel {
    padding: 0 55px;
    .slides li {
      margin: 0 15px !important;
      width: 81px !important;
      height: 81px !important;
      img {
        width: 71px;
        height: 71px;
      }
    }
    &:hover .flex-direction-nav .flex-prev,
    &:hover .flex-direction-nav .flex-next {
      top: 30px;
    }
  }
  .node-product .flexslider#slider {
    .fa-arrows-alt {
      right: 0;
    }
  }
}


@media (min-width: 321px) and (max-width: 374px) {
  .node-product .flexslider#carousel {
    .slides li {
      margin: 0 14px !important;
      width: 80px !important;
      height: 80px !important;
      img {
        width: 70px;
        height: 70px;
      }
    }
    &:hover .flex-direction-nav .flex-prev,
    &:hover .flex-direction-nav .flex-next {
      top: 30px;
    }
  }
  .node-product .flexslider#slider {
    .fa-arrows-alt {
      right: 0;
    }
  }
}

@media (max-width: 320px) {
  .node-product .flexslider#carousel {
    padding: 0 30px;

    .slides li {
      margin: 0 22px !important;
      width: 65px !important;
      height: 65px !important;
      img {
        width: 55px;
        height: 55px;
      }
    }
    &:hover .flex-direction-nav .flex-prev,
    &:hover .flex-direction-nav .flex-next {
      top: 30px;
    }
  }
  .node-product .flexslider#slider {
    .fa-arrows-alt {
      right: 0;
    }
  }
}

// Emergency Alert Message styles
.emergency-alert {
  background: #f0f0f0;

  .container.accordion.md-accordion {
    padding-left: 20px;
    a {
      text-decoration: none;
    }
    .card-body {
      p {
        margin-bottom: 20px;
        padding-right: 30px;
      }
      a:hover {
        text-decoration: underline;
      }
    }
    h2 {
      padding-right: 30px;
      font-family: 'PlutoSansRegular', sans-serif;
      position: relative;
      margin-top: 20px;
      line-height: 1;
      margin-bottom: 15px;
      color: #5d9632;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }

      i {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        color: #5d9632;
        background: #fff;
        position: absolute;
        right: 0;
        transform: rotate(-45deg);
        transition: transform 0.5s linear;
        &:before {
          position: relative;
          top: -1px;
          left: -2px;
        }
      }

      &:hover, &:active {
        text-decoration: none;
      }
    }
    a.collapsed h2 i {
      transform: rotate(0);
    }
  }
}

/* OneTrust pop-up style. */
.optanon-alert-box-wrapper .optanon-button-more .optanon-alert-box-button-middle button,
#onetrust-consent-sdk #onetrust-pc-btn-handler:focus {
  outline: none !important;
}

#onetrust-consent-sdk #onetrust-button-group #onetrust-pc-btn-handler.cookie-setting-link:hover,
#onetrust-consent-sdk #onetrust-button-group #onetrust-pc-btn-handler.cookie-setting-link:active,
#onetrust-consent-sdk #onetrust-button-group #onetrust-pc-btn-handler.cookie-setting-link:focus,
#onetrust-consent-sdk #onetrust-pc-btn-handler.cookie-setting-link,
#onetrust-consent-sdk #onetrust-pc-btn-handler {
  color: #fff !important;
  border-color: #5D9632 !important;
  opacity: 1;
}

#onetrust-consent-sdk #onetrust-pc-btn-handler.cookie-setting-link:hover,
#onetrust-consent-sdk #onetrust-pc-btn-handler:hover {
  opacity: 0.7;
  outline: none;
}

.cookie-setting-link:hover,
.cookie-setting-link:active,
.cookie-setting-link:focus {
  outline: none;
  border: none;
}

/* OneTrust cookie policy. */
.kerr-page-cookie-policy h1 {
  margin-bottom: 25px;
}

button#ot-sdk-btn.ot-sdk-show-settings,
button#ot-sdk-btn.optanon-show-settings {
  font-family: Helvetica, Arial, sans-serif;
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 1.3em;
  background-color: #5d9632;
  color: #fff;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  border: none;
}
button#ot-sdk-btn.ot-sdk-show-settings:hover,
button#ot-sdk-btn.optanon-show-settings:hover {
  text-decoration: none;
  background-color: #8cc63e;
  color: #ffffff;
  outline: none;
}
#ot-sdk-cookie-policy {
  color: #706f73;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy #cookie-policy-title,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-sdk-cookie-policy-group {
  color: #4a4a4a;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 20px;
  letter-spacing: 0.3px;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy h5,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy h6,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy li,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy p,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy a,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy span,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy td,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy div#cookie-policy-description,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy p.ot-sdk-cookie-policy-group-desc {
  font-family: "Helvetica Neue LT Std Lt", Arial, sans-serif;
  color: #706f73 !important;
  line-height: 20px;
  font-size: 16px;
  letter-spacing: 0.3px;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy #cookie-policy-description,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-sdk-cookie-policy-group-desc,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-table-header,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy a,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy span,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy td {
  font-size: 16px !important;
}

.ot-sdk-cookie-policy.ot-sdk-container {
  table {
    span {
      word-break: break-all;
    }
  }
}

#onetrust-pc-sdk #close-pc-btn-handler.ot-close-icon:focus {
  outline: none;
}

#onetrust-banner-sdk {
  border: none;
  outline: none;
}
