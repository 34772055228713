#support-header {
  @media (min-width: 768px) {
    .header-image-responsive {
      height: 180px;
      img {
        height: 180px;
      }
    }
  }
  .z-index-container {
    position: relative;
  }
  h1 {
    padding-top: 5px;
    color: #4a4a4a;
    font-size: 30px;
    font-family: $font-light, Arial, sans-serif;
  }
  h3 {
    color: #706f73;
    font-size: 16px;
    letter-spacing: 0.3px;
    font-weight: 400;
    line-height: 20px;
    font-family: "Helvetica Neue", Arial, sans-serif;
    @media (min-width: $screen-sm) {
      max-width: 550px;
    }
  }
}

.support-main-content {
  padding-top: 35px;
  padding-bottom: 35px;
  p,
  a {
    font-weight: 400;
    line-height: 20px;
  }
  p {
    margin-bottom: 0;
    color: #706f73;
    font-size: 14px;
    font-family: "Helvetica Neue", Arial, sans-serif;
    &:not(:first-of-type) {
      a {
        padding-top: 20px;
      }
    }
  }
  a {
    font-size: 16px;
    display: inline-block;
  }
}
