#contact-header, .header-page-1 {
  .header-image-responsive {
    height: 180px;
    img {
      height: 180px;
    }
  }
}

#contact-distrib {
  padding-top: 42px;
  padding-bottom: 48px;
  form {
    max-width: 520px;
    .hs-form-field {
      div.input {
        margin-right: 0;
      }
      label {
        margin-bottom: 0;
      }
      .hs-field-desc {
        margin-top: -4px;
        margin-bottom: 7px;
        border-bottom: none;
      }
    }
    fieldset {
      max-width: 100%;
      .field {
        margin-bottom: 22px;
      }
    }
    .form-columns-2 {
      .hs-form-field:first-of-type {
        @include breakpoint($screen-xs + 1) {
          padding-right: 10px;
        }
      }
      .hs-form-field:last-of-type {
        @include breakpoint($screen-xs + 1) {
          padding-left: 10px;
        }
      }
    }
    .hs-input {
      box-shadow: none;
    }
    .hs-input:not([type="checkbox"]) {
      border: 1px solid #c2c2c2;
      background-color: #fff;
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;
      &:not(textarea) {
        min-height: 31px;
      }
    }
    .hs-input.error {
      border-color: #ff9f9f;
    }
    select {
      background: url("../img/select-bg.svg") no-repeat center right;
      background-position-x: calc(100% - 9px);
    }
    textarea {
      vertical-align: top;
      resize: none;
      min-height: 81px;
    }
    ul.hs-error-msgs {
      padding: 0;
      li {
        label {
          top: -3px;
          color: #ff5d5d;
          font-size: 13px;
          margin: 0;
          padding: 0 !important;
          background: transparent;
          border: none;
          box-shadow: none;
          line-height: normal;
        }
      }
    }
  }
  .hs-form-required {
    color: #ff5d5d;
  }
  .hs_error_rollup {
    height: 15px;
    ul.hs-error-msgs {
      li {
        margin-bottom: 0;
        label {
          top: 0;
        }
      }
    }
  }
  .hs-form {
    .actions {
      margin-top: 4px;
      margin-left: 0;
      padding-left: 0;
    }
  }
}
